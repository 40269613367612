
.header {
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}
.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; }
.contribute {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; }
.profile{
    margin-top:70px;
}
.link-account-container a{
  margin:10px;
}
.credits{
  font-weight: bold;
  font-size: 45px;
  display:table;
  margin:0 auto;
}
.credits-card{
  display:table;
  margin:0 auto;
}
.sponsrSearchButton{
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.sponsrSearchButton:hover {
  text-decoration: none;
  color: #fff;
}
.sponsrSearchButton {
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  padding:20px;
  font-size: 18px;
  color: white;
  background-color: #ef5456;
}
.editionButtons {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.multiselectCountries {
  margin-top: 8px;
}
.selectCountriesSpan {
  font-size: 14px;
}
.toggleButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 6px;
}
.missingValues {
  margin: 5px;
  font-size: 18px;
  font-weight: bold;
  color: red;
}
.optionsToSave {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.coursesSectionTitle {
  margin-top: 15px;
  margin-bottom: 10px;
}
.coursesField {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 5%;
  padding: 5%;
  border: 2px solid #ccc;
  border-radius: 4px;
}
.coursesField  div:first-child {
  margin-bottom: 2%;
}
.coursesButtons {
  margin-top: 10px;
}
.coursesButtons Button:first-child {
  margin-right: 5px;
}
.skillItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px;
}
.textareaForm {
  resize: none;
}
.heade-details-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 15px;
}
.resumeImg {
  width: 70%;
}
.resumeLinksContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.resumeLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  margin-right: 10%;
  margin-top: 0;
}
.resumeLinksSpanContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.resumeLinksSpan {
  text-decoration: underline;
  color: #037bff;
  font-size: 19px;
}
.resumeLinksSpanImprovement {
  text-decoration: underline;
  color: #037bff;
  font-size: 19px;
  padding: 0px;
}
.resumeLinksSpan:hover, 
.resumeLinksSpanImprovement:hover {
  cursor: pointer;
}
.resumePoints {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 20px;
}
.resumePoints img {
  width: 20px;
}
.resumePoints span {
  margin-left: 15px;
}
.seeResume {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
}
.resumeModalButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.noResumeYet {
  text-decoration: underline;
}
.warningsDesiredJobs, .warningsDesiredJobsButton {
  font-size: 19px;
}
@media screen and (max-width: 767px) {
  .resumeLinksContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 25px;
  }
  .resumeLinks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-right: 0;
    margin-top: 10px;
  }
  .resumeImgContainer {
    text-align: center;
  }
  .resumeImg {
    width: 40%;
  }
}
.visaSettings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (max-width: 600px) {
  .coursesSectionBody > * {
    margin-right: 0;
  }
}
.schoolprestigeSpan {
  font-size: 25px;
  font-weight: bold;
  padding: 15px;
}
.alignButtonModal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; 
}
.needsImprovementContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 8%;
}
.needsImprovementSpan {
  color: red;
  font-size: 18px;
  text-align: center;
}
.needsImprovementSpan a {
  text-decoration: underline;
}
.alertIcon {
  margin-top: 3px;
  margin-right: 5px;
}
.needsImprovementModalContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.needsImprovementModalContainer span {
  margin-bottom: 15px;
}
.needsImprovementModalButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.noDataSpan {
  margin: 5%;
  font-size: 20px;
}
.noDataSpanAchievement {
  color: black;
  font-weight: 600;
}
.warningContainer {
 margin-top: 10px;
}
.warningSpan {
  color: red;
}
.warningSpanField {
  font-size: 80%;
  color: #dc3545;
}
.warningSettings {
  font-size: 15px;
  color: #dc3545;
}
.jobTaskContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;  
  margin-bottom: 10px;
}
.jobTaskContainer span {
  width: 75%;
}
.jobTaskDeleteButton {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.skillsLabels, .skillsLabelsWithIcon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.achievementsLabels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 35px;
}
.skillsLabels > img, .skillsLabelsWithIcon > img {
  margin-top: 0;
}
@media screen and (max-width: 990px) {
  .skillsLabels, .skillsLabelsWithIcon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .skillsLabels > img, .skillsLabelsWithIcon > img {
    margin-top: 5%;
  }
}
.profileWarningsLabels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;    
  -ms-flex-wrap: wrap;    
      flex-wrap: wrap;
}
.profileWarningsSpan {
 color: red;
}
.profileWarningsButton {
  padding: 0px;
}
.createResumeContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 60%;
}
.createResumeContainerSpan {
  text-align: end;
  font-size: 20px;
  font-weight: bold;
  color: #2a3d92;
}
.createResumeContainerButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  margin-top: 5%;
  margin-bottom: 0px;
}
@media screen and (max-width: 767px) {
  .achievementsLabels {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
  }
  .createResumeContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .createResumeContainerSpan {
    text-align: start;
  }
  .createResumeContainerButton {
    margin-top: 0px;
    margin-bottom: 15px;
  }
  
}
.productCard {
  background-color: rgb(241, 244, 248);
}
.productCardContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 18px;
  font-weight: 500;
}
.productCardName {
  width: 60%;
}
.productCardStatus {
  margin-left: 10px;
  color: red;
  text-transform: capitalize;
}
.countryResidencyWarning {
  border: 1px solid red;
  border-radius: 5px;
}
@media screen and (max-width: 450px) {
  .productCardName {
    width: 100%;
  }
  .productCardContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    font-size: 18px;
    font-weight: 500;
  }
}
.completeProfileData {
  color: red;
  font-weight: bold;
}
.required-field {
  color: red;
  font-size: 14px;
}
.lastUpdate {
  font-size: 18px;
  font-weight: bold;
}
.warningsLabels {
  margin-bottom: 0.8em;
  font-size: 16px;
  font-weight: bold;
  color: orange;
}
.loadContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.modalButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.warningsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.unemployedModalButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.regularWarningSpan {
  color: black;
  font-size: 19px;
  text-align: center;
}
.goodResumeImg {
  width: 20px;
}
.requestReviewLink {
  text-decoration: underline;
  color: #037bff;
}
.requestReviewLink:hover {
  cursor: pointer;
}
.requestReviewLink-Cursor:hover {
  cursor: pointer;
}
.alignPopupsButtons {
  float: right;
}
.modalLabel {
  background-color: #2a3d92;
  color:white;
  font-weight: bold;
  font-size:12px;
  padding:5px;
  margin-left:10px;
  border-radius:4px;
  white-space: normal;
  display: inline-block;
}
.titlesContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.titleWarning {
  text-decoration: underline;
  -webkit-text-decoration-color: #dc3545;
          text-decoration-color: #dc3545;
  text-underline-position: under
}
.infoIcon {
  width: 20px;
}
.warningTooltipContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tooltipContent {
  text-align: left;
}
.tooltipContent-link {
  text-decoration: underline;
  color: white;
}
.tooltipContent-link:hover {
  cursor: pointer;
}
.gapYearWarning {
  color: red;
  font-weight: 500;
}
.skillWarning {
  font-size: 14px;
}
.missingHardSkillsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.warningSettingsFields {
  border: 1px solid red;
}