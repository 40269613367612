
.center{
    display:table;
    margin:0 auto;
}
.hide{
    display:none;
}
.currentPlanLabel{
    color: white;
    padding: 5px;
    background-color: #2a3d92;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.highlightPlan{
    border-color: #2a3d92;
    border-style: solid;
    border-radius: 5px; 
}
.helpLink{
    margin-right: 10px;
}
.extraContent {
    margin-top: 0;
    font-size: 0.9em;
    color: #555;
    text-align: center;
}
.extraContent p {
  margin: 0;
  padding: 0;
}