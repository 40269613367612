.bookmark{
  cursor: pointer;
  border:none;
  font-size: 22px;
  background:none;
  float:right;
}

.bookmark svg{
  fill:white;
  stroke:#ef5456;
  stroke-width: 2px;
  width:30px;
  
}

.bookmarked svg{
  fill:#ef5456;
}


