.navigation {
  padding: 0px;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: white;
  width: 100%;
  /* height: 76px; */
  z-index: 100;
  -webkit-box-shadow: 0 1px 6px 1px rgba(70, 70, 70, .3);
          box-shadow: 0 1px 6px 1px rgba(70, 70, 70, .3);
}
.logo {
  height: 50px;
  cursor: pointer;
}
.logo-small {
  height: 35px;
  margin-left: 10px;
  display: none;
}
@media (min-width: 992px) and (max-width: 1130px) {
  .logo-small {
    display: unset;
  }
  .logo {
    display: none;
  }
}
@media (min-width: 992px) {
  .navigation {
    height: 58px;
  }
  .logo {
    margin-top: 4px;
  }
}
.nav-icon {
  cursor: pointer;
  border: none;
  word-wrap: normal;
  background: none;
  height: 32px;
}
.nav-icon:focus {
  outline: 0;
}
.nav-icon svg {
  display: none;
}
.nav-icon-selected svg {
  display: none;
}
.nav-icon div {
  color: #2a3d92;
}
.nav-icon:hover div {
  font-weight: bold;
}
.nav-icon-selected div {
  font-weight: bold;
}
@media (min-width: 992px) {
  .nav-icon {
    width: 90px;
    height: 44px;
    padding: unset;
  }
  .nav-icon div {
    display: table;
    margin: 0 auto;
  }
  .nav-icon svg {
    display: block;
    margin: 0 auto;
    width: 22px;
    height: 21px;
    fill: white;
    stroke: #2a3d92;
    stroke-width: 2px;
  }
  .nav-icon-myvisa svg {
    width: 25px;
    height: 22px;
    stroke-width: 12px;
  }
  .nav-icon-myprofile svg {
    stroke-width: 6px;
  }
  .nav-icon-selected svg {
    fill: #2a3d92;
  }
  .nav-icon:hover > svg {
    fill: #2a3d92;
  }
}
.fr-icon,
.en-icon {
  width: 20px;
  height: 15px;
  background-size: cover;
  background-repeat: no-repeat;
}
.fr-icon {
  width: 20px;
  height: inherit;
  background-size: 20px 15px;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAj0lEQVRIS2N01pktzsLBsIeBgUGHAQ+wjmXEJ40hp1hXgVc9IwPjFaY/jC6M7iazLxOyHGQStR0AMhPkCJAD/oM48uLBeF3cv5aXpBB4q6WLV/3Bt2/A8qMOGA2B0RAYDYHREBgNgdEQGA2B0RAYFCEwYI1SBgaG84ygZjkT+/99TIyMWvRsloMsZ/7D5AkAU5uxaIbIlLYAAAAASUVORK5CYII=");
}
.en-icon {
  width: 20px;
  height: inherit;
  background-size: 20px 15px;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAWCAYAAAChWZ5EAAABRklEQVRIS2PMFlaL3aLgP+8jqwgLAwgwM4Ephr//IDQRfCYONobSIxUQ9cSDl38ZGFMZ5Y1Lf39gEoRYTiZgZGNlKD9TR47ul4wCFp3////9y7B1VioDIyMDQ0LLObBB86uNiOYzs7IwHJtlTZIDpsxbAlYPd8C22akMjAwMDPFQByyoMSKaT5kDrLv////1m0FM3phkn8O8zMrFzrD+VDpJIbDu/iNoCKA5gBSfw2xk42JnWEe2A0bTwGgaGE0DaGmApMwMVUxROSDkPPH/vx+/GCTkjMDGMbEyg0vAv7//Es1n5+VgWLE3niS3wwuipolz/rOzszGkRoeRZACliuF1wYA7oJ2d8z/IN0GKcpR6iiT98CgYcAcMeBQMuAPa2TlfMDAwiJMUgdRT/IyxhZ3Ll5nh/xwGBgYx6plLlEnP/jIwZgAAuBcdYY8/WloAAAAASUVORK5CYII=");
}
@media (min-width: 992px) {
  .en-icon, .fr-icon {
    height: 50px;
  }
}
.modalLabel {
  background-color: #0F9D58;
  color:white;
  font-weight: bold;
  font-size: 15px;
  padding: 8px 12px;
  border-radius: 4px;
  display: inline-block;
  white-space: normal;
  cursor: pointer;
}
.notification-dot {
  position: absolute;
  top: 0;
  right: 32px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}
@media (max-width: 991px) {
  .navigation {
    display: none !important;
  }

  .navigation.navigation-admin {
    display: block !important;
  }

  .navigation-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    background-color: #fff;
    padding-bottom: 40px;
    z-index: 100;
  }

  .mobile-top-bar {
    position: fixed;
    top: 0; left: 0; right: 0;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0 1rem;
    -webkit-box-shadow: 0 1px 6px 1px rgba(70, 70, 70, .3);
            box-shadow: 0 1px 6px 1px rgba(70, 70, 70, .3);
    background-color: #fff;
    z-index: 999;
  }

  .mobile-logo-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mobile-logo-wrapper .logo {
    height: 40px;
    margin-top: 0;
  }

  .mobile-top-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 12px;
  }

  .mobile-bottom-bar {
    position: fixed;
    bottom: 0; left: 0; right: 0;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    background-color: #fff;
    -webkit-box-shadow: 0 -1px 6px 1px rgba(70, 70, 70, .3);
            box-shadow: 0 -1px 6px 1px rgba(70, 70, 70, .3);
    z-index: 999;
  }

  .nav-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    width: 60px;
    height: auto;
    margin: 0;
    border: none;
    background: none;
    text-decoration: none;
    color: #2a3d92;
  }

  .nav-icon svg {
    display: block !important;
    width: 24px;
    height: 24px;
    fill: white;
    stroke: #2a3d92;
    stroke-width: 2px;
    margin-bottom: 2px;
  }

  .nav-icon div {
    display: block !important;
    font-size: 10px;
    white-space: nowrap;
    margin: 0;
    line-height: 1;
  }

  .nav-icon-selected svg {
    fill: #2a3d92;           
    stroke: #2a3d92;
  }
  .notification-dot {
    top: 0px;
    right: 17px;
  }
}
.nav-icon-myvisa svg {
  stroke-width: 15px !important;
}
.nav-icon-myprofile svg {
  stroke-width: 4px !important;
}
