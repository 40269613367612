.radius-container{
  margin-top:5px;
  display:'inline';
  margin-bottom: 25px;
}

.radius{
  margin-top: 5px;
  width:100%;
  -webkit-appearance: none;
  height: 15px;
  border-radius: 5px;   
  background: #d3d3d3;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
}

.radius::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%; 
  background: #2a3d92;;
  cursor: pointer;
}

.radius::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

@media (min-width: 1110px){
  .radius{
    max-width: 220px;
    float: right;
  }
}

.hide{
  display:none;
}