
.hide{
  display:none;
}
.potentialSponsorshipsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.potentialSponsorshipsContainer-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-right: 25px;
  margin-bottom: 10px;
}
@media screen and (max-width: 980px) {
  .potentialSponsorshipsContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.error-message {
  color: #ed193a;
  font-size: 18px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 4px;
  padding:10px;
  border: #ed193a 2px solid;
  border-radius: .8em;
}
.job-buttons-container button{
  margin-right:7px;
  margin-top:10px;
  width:100%;
}
.jobtitle{
  font-weight: bold;
  text-decoration:none;
  color: #ef5456;
  margin-bottom:7px;
  font-size:20px;
}
.new-job{
  margin-left:5px;
  font-weight:bold;
  color: #0F9D58;
}
.expired-job{
  margin-left:5px;
  font-weight:bold;
  color: #ff0000;
}
.exclusive{
  font-weight:bold;
  color: #ed193a;
  margin:5px;
}
.company{
  margin-bottom:3px;
}
.other-employer-name-container{
  max-height: 130px;
  overflow-y: auto;
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}
.other-employer-name{
  font-style: italic;
  font-size: 14px;
}
.industry-container{
  margin:20 20 20 20;
  display:block;
  width:100%;
  word-wrap:break-word;
}
.industry{
  background-color: #7f8388;
  color:white;
  font-weight: bold;
  font-size:12px;
  padding:3px;
  margin-top:10px;
  margin-right:10px;
  border-radius:4px;
  white-space: normal;
  display: inline-block;
}
div.address{
  margin: 15px 0 15px 0;
}
div.address.sm{
  margin: 0 0 7px 0;
}
.go-back-button{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  color:#2a3d92;
  border:1px solid #2a3d92;
}
.button-container{
  display:table;
  margin:0 auto;
  max-width: 100%;
}
.alert-container{
  width: 250px;
}
.alert-container *{
  max-width:250px;
}
.details-reco{
  display:table;
  margin:20px auto;
  max-width: 600px;
}
.details-reco p{
  margin-bottom: 10px;
}
.more-info-button{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  color:#2a3d92;
  border:1px solid #2a3d92;
  margin:unset;
  margin-bottom: 10px;
  width:100%;
}
button.more-info-button:hover{
  color:white;
  background:#2a3d92;
  margin:unset;
  margin-bottom: 10px;
}
button.more-info-button-active{
  color:white;
  background:#2a3d92;
  margin:unset;
  margin-bottom: 10px;
}
.apply-button{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  color:#ef5456;
  border:1px solid #ef5456;
  margin:unset;
  width:100%;
  margin-bottom: 10px;
}
button.apply-button:hover{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  color:white;
  background:#ef5456;
  margin:unset;
  width:100%;
  margin-bottom: 10px;
}
.go-back-button::before{
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2026.676%2026.676%22%20style%3D%22enable-background%3Anew%200%200%2026.676%2026.676%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%09%3Cpath%20fill%3D%22%232a3d92%22%20d%3D%22M26.105%2C21.891c-0.229%2C0-0.439-0.131-0.529-0.346l0%2C0c-0.066-0.156-1.716-3.857-7.885-4.59%0A%09%09c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0%2C0.213-0.115%2C0.406-0.304%2C0.508c-0.188%2C0.098-0.413%2C0.084-0.588-0.033L0.254%2C13.815%0A%09%09C0.094%2C13.708%2C0%2C13.528%2C0%2C13.339c0-0.191%2C0.094-0.365%2C0.254-0.477l11.857-7.979c0.175-0.121%2C0.398-0.129%2C0.588-0.029%0A%09%09c0.19%2C0.102%2C0.303%2C0.295%2C0.303%2C0.502v4.293c2.578%2C0.336%2C13.674%2C2.33%2C13.674%2C11.674c0%2C0.271-0.191%2C0.508-0.459%2C0.562%0A%09%09C26.18%2C21.891%2C26.141%2C21.891%2C26.105%2C21.891z%22%2F%3E%0A%3C%2Fsvg%3E');
  background-position: left center;
  background-repeat: no-repeat;
  fill:white;
  background-size: contain;
  content: ' ';
  display: inline-block;
  height: 15px;
  position: relative;
  top: 2px;
  width: 20px;
}
button.go-back-button:hover::before{
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2026.676%2026.676%22%20style%3D%22enable-background%3Anew%200%200%2026.676%2026.676%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%09%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M26.105%2C21.891c-0.229%2C0-0.439-0.131-0.529-0.346l0%2C0c-0.066-0.156-1.716-3.857-7.885-4.59%0A%09%09c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0%2C0.213-0.115%2C0.406-0.304%2C0.508c-0.188%2C0.098-0.413%2C0.084-0.588-0.033L0.254%2C13.815%0A%09%09C0.094%2C13.708%2C0%2C13.528%2C0%2C13.339c0-0.191%2C0.094-0.365%2C0.254-0.477l11.857-7.979c0.175-0.121%2C0.398-0.129%2C0.588-0.029%0A%09%09c0.19%2C0.102%2C0.303%2C0.295%2C0.303%2C0.502v4.293c2.578%2C0.336%2C13.674%2C2.33%2C13.674%2C11.674c0%2C0.271-0.191%2C0.508-0.459%2C0.562%0A%09%09C26.18%2C21.891%2C26.141%2C21.891%2C26.105%2C21.891z%22%2F%3E%0A%3C%2Fsvg%3E');
}
button.go-back-button:hover{
  color:white;
  background:#2a3d92;
}
.posted{
  color: #7f8388;
  margin-left:60%;
}
.location{
  color: #7f8388;
  margin-bottom:5px;
}
.location::before{
  background-image: url('data:image/svg+xml;charset=utf-8,<svg%20xmlns%3D"http%3A//www.w3.org/2000/svg"%20width%3D"1178"%20height%3D"1767"><path%20fill%3D"%23b0b4b6"%20stroke%3D"%23b0b4b6"%20d%3D"M884%20589c0-81-29-151-87-208-57-58-127-86-208-86s-151%2028-208%2086-86%20127-86%20208%2028%20151%2086%20208c57%2058%20127%2087%20208%2087s151-29%20208-87c58-57%2087-127%2087-208zm294%200c0%2084-13%20152-38%20206l-419%20891a148%20148%200%200%201-264%200L39%20795C13%20741%200%20673%200%20589c0-162%2058-301%20173-416S427%200%20589%200s302%2058%20417%20173%20172%20254%20172%20416z"/></svg>');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  content: ' ';
  display: inline-block;
  height: 15px;
  position: relative;
  top: 2px;
  width: 20px;
}
.description-details{
  margin-bottom:25px;
  margin-top:25px;

  text-align: justify;
}
.description-details h4{
  font-size: 16px;
}
.description-details h3{
  font-size: 16px;
}
.description-details h2{
  font-size: 16px;
}
.description-details h1{
  font-size: 16px;
}
.right-side a>img{
  margin-right:10px;
  margin-top:10px;
  width:48px;
  height:48px;
}
.visa-guaranteed-container {
  width: 80%;
  font-size: 18px;
  font-weight: bold;
  color: green;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.visa-guaranteed-container > img {
  width: 25px;
  height: 25px;
  margin-left: 15px;
  margin-right: 5px;
}
.visa-guaranteed-container > span {
  text-align: center;
}
.infoSpan {
  margin-left: 35px;
  font-size: 24px;
}
.infoSpan:hover {
  cursor: pointer;
}
.visa-j1program {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: green;
  text-align: center;
  margin-top: 20px;
}
.greeFlag-AppliedJob {
  float: right;
  color: green;
  font-weight: bold;
  font-size: 20px;
}
.eligibilityModalBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.eligibilityModalBody > img {
  height: 20px;
  width: 20px;
}
.eligibilityButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-top: 15px;
}
.no-visa-match {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.noVisaMatchContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 15px;
  width: 250px;
}
.noVisaMatchContainerText {
   color: red;
   background-color: #fff996;
   padding: 10px;
   text-align: justify;
   font-weight: bold;
   font-size: 0.8rem;
}
.noVisaMatchContainerTextLink {
   color: red;
   text-decoration: underline;
   margin-top: 14px;
}
.marginTop {
   margin-top: 1em;
}
.noVisaMatch {
  border: 1px solid #2a3d92;
  border-radius: 5px;
  padding: 7%;
  font-size: 24px;
  color: red;
}
@media screen and (min-width: 640px) {
  .apply-button{
    width: 160px;
    display:unset;
    margin-left:10px;
    margin-right:10px;
  }
  .more-info-button{
    width:160px;
  }
  button.apply-button:hover{
    width: 160px;
    display:unset;
    margin-left:10px;
    margin-right:10px;
  }
  .jobtitle{
    margin:20px;
  }
  .right-side a>img{
    width:38px;
    height:38px;
  }
  .logo{
    margin-right:50px;
  }
  .button-table{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
  }
  .button-table button{
    width: 160px;
    margin-left:10px;
    margin-right:10px;
  }
  .button-table button:hover{
    margin-left:10px;
    margin-right:10px;
  }
  .alert-container button{
    position: absolute;
    left: 23%;
    bottom: 0;
  }
}
@media screen and (min-width: 490px) {
  .job-buttons-container button{
    width: unset;
  }
  .visa-match-guaranteed{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .visa-match-guaranteed > fieldset {
    margin-right: 10px;
  }
  .visa-match-guaranteed > div {
    text-align: center;
    margin-top: 2px;
  }
  .visa-guaranteed-container > div{
    padding-left: 7px;
    padding-right: 7px;
  }
}
@media screen and (min-width: 992px) {
  .right-side{
    display:table;
    margin:0 auto;
  }
}
@media screen and (max-width: 768px) {
  .eligibilityModalBody {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .eligibilityButtons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 15px;
  }

  .verifyButton {
    margin-bottom: 20px;
  }
}
.unlockApplyButton {
  float: right;
}
.socTitleLabel {
  font-size: 18px;
  font-weight: 600;
}
.applyingProcessContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.tipContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tipContainer img {
  width: 20px;
  margin-right: 5px;
}
.tipLink {
  color: #0056b3;
  text-decoration: underline;
}
.tipLink:hover {
  cursor: pointer;
}
.info-icon {
  width: 28px;
  height: 21px;
}
.tooltipContent {
  text-align: left;
}
h5.skillsMatchesTitle {
  font-size: 0.8em;
  color: dimgrey;
}
.skillsMatchesContainer{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.skillsMatchesContainer span {
  padding: 10px;
  border: 1px solid red;
  border-radius: 1px;
  font-size: 17px;
  font-weight: 600;
  color: red;
  margin: 5px;
}
.alignPopupsButtons {
  float: right;
}
.sectionTitle {
  font-size: 17px;
  font-weight: 600;
}
.hiddenCompanyBannerLg, .hiddenCompanyBannerSm {
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.43922);
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.43922);
}
.hiddenCompanyBannerLg {
  height: 220px;
  width: 100%;
  max-width: 680px;
}
.hiddenCompanyBannerSm {
  width: 100%;
}
.footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
  padding: 1rem 0;
}
.bottom-actions-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.jobcard-button-apply {
  background-color: #283891;
  border: 1px solid #364151;
  border-radius: 4px;
  padding: 6px 10px;
  color: #fff;
  cursor: pointer;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: auto;
}
.jobcard-button-report {
  background-color: #EAE8E5;
  border: 1px solid #364151;
  border-radius: 4px;
  padding: 6px 10px;
  color: #070614;
  cursor: pointer;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.actions-button{
  display:table;
  width:100%;
}
.actions-button div{
  display:table;
  margin:0 auto;
}
button.edit{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  color:#2a3d92;
  border:1px solid #2a3d92;
}
button.edit:hover{
  background:#2a3d92;
  color:white;
}