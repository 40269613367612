
.stageSpan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 20px;
}
.getHiredButtonsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.getHiredButtonsContainerFeasibility {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.getHiredButtons {
  background-color: #2a3d92;
  border-color: #2a3d92;
  width: 300px;
}
@media screen and (max-width: 995px) {
  .getHiredButtonsContainer, .getHiredButtonsContainerFeasibility {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .getHiredButtons {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
}
