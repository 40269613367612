
.entrybox {
  width: 100%;
  margin-bottom: 72px;
}
.header {
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}
.input {
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0.75rem 1rem;
  border: 2px solid #c3c8ce;
  border-radius: 4px;
  width: 100%;

  background-color: #fff;
  color: #333f48;
  margin-bottom: 0.75rem;
  min-width: 0;
  text-align: center;
  outline: none;
}

