.login {
   width: 100%;
   margin: 0 auto;
   position: relative;
   top: 90px;
   background-color: rgba(255, 255, 255, .80);
   border: 2px solid #2a3d92;
   border-radius: 20px;
}
@media screen and (min-width: 490px) {
   .login {
      width: 415px;
      position: absolute;
      left: 70px;
   }
}
.logo {
   display: table;
   margin: 0 auto;
   height: 50px;
}
.waiting {
   .loading {
      display: block;
   }
}
.alternative {
   /*background: var(--colorWhite);*/
   padding-bottom: 12px;
   font-size: 16px;
   text-align: center;
   color: #2a3d92;
}
.subheading {
   font-size: 16px;
   text-align: center;
   color: black;
}
.alternative-link {
   color: #2a3d92;
   font-weight: bold;
   cursor: pointer;
   margin: 5px;
}
.sso-link {
   color: #ed193a;
   font-weight: 800;
   margin: 5px;
   text-decoration: underline;
}
.sso-link:hover {
   color: #ed193a;
   font-weight: 800;
   margin: 5px;
}
.loading {
   display: none;
   text-align: center;
   margin: 24px auto;
}
.creds-container {
   padding: 16px 12px;
   border-bottom: 2px solid #e3e3e3;
}
.name-container {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: justify;
       -ms-flex-pack: justify;
           justify-content: space-between;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
       -ms-flex-direction: column;
           flex-direction: column;
   margin-bottom: 0.75rem;
}
.names {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
       -ms-flex-direction: row;
           flex-direction: row;
}
.social-container {
   padding: 16px 12px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
}
.social-container a {
   width: 100%;
}
.toggle-button-container {
   padding-left: 12px;
   padding-right: 12px;
   padding-bottom: 8px;
   color: #2a3d92;
}
.toggle-button-container a {
   display: table;
   margin: 0 auto;
   width: 100%;
}
.header {}
.heading {
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
   text-align: center;
   color: #2a3d92;
}
.input {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0.75rem 1rem;
  border: 2px solid #c3c8ce;
  border-radius: 4px;
  width: 100%;

  background-color: #fff;
  color: #333f48;
  margin-bottom: 0.75rem;
  min-width: 0;
}
.input:focus {
   border-color: #2a3d92;
}
.password {
   margin-bottom: 0px;
}
.field-message {
   color: #ed193a;
   font-size: 12px;
   margin-left: 5px;
   margin-right: 5px;
   font-weight: 600;
}
.field-error {
   border: 1.5px solid #de5245;
}
.message {
   color: #ed193a;
   font-size: 16px;
   /* margin-left: 12px;
   margin-right: 12px;
   margin-bottom: 4px; */
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   font-weight: 600;
   margin-bottom: 1rem;
   text-align: center
}
.sso-link-error-message {
   color: #ed193a;
   font-weight: 800;
   text-decoration: underline;
}
.error-message-sso-links {
   color: #ed193a;
   font-weight: 800;
}
.smallButton {
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58

}
.smallButton:hover {
   text-decoration: none;
   color: #fff;
}
.smallButton {
   text-decoration: none;
   text-align: center;
   border-radius: 6px;
   border-style: solid;
   border-width: 2px;
   cursor: pointer;
   -webkit-transition: 0.1s background-color ease;
   transition: 0.1s background-color ease;
   color: #2a3d92;
   background-color: #f7f4f2;
   border-color: #2a3d92;
}
.smallButton:hover {
   color: #2a3d92;
}
.linkedinButton {
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.linkedinButton:hover {
   text-decoration: none;
   color: #fff;
}
.linkedinButton {
   text-decoration: none;
   text-align: center;
   border-radius: 6px;
   border-style: solid;
   border-width: 2px;
   cursor: pointer;
   -webkit-transition: 0.1s background-color ease;
   transition: 0.1s background-color ease;
   background-color: #0084bf;
   border-color: #0084bf;
   margin-right: 15px;
}
.googleButton {
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.googleButton:hover {
   text-decoration: none;
   color: #fff;
}
.googleButton {
   text-decoration: none;
   text-align: center;
   border-radius: 6px;
   border-style: solid;
   border-width: 2px;
   cursor: pointer;
   -webkit-transition: 0.1s background-color ease;
   transition: 0.1s background-color ease;
   background-color: #de5245;
   border-color: #de5245;
}
.button {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  color: #fff;
  background: #0F9D58;
}
.button:hover {
   text-decoration: none;
   color: #fff;
}
.button {
   font-size: 16px;
   background-color: #2a3d92;
   border-color: #2a3d92;
}
.hint {
   /* @apply --small; */
   color: #2a3d92;
   margin-bottom: 0.75rem;
   margin-left: 5px;
   ;
}
.background-login {
   position: fixed;
   width: 100%;
   height: 100%;
   overflow-y: scroll;
   background-image: url("../../images/usponsorme-login.jpg");
   background-repeat: no-repeat;
   background-size: cover;
}
.divider {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   text-align: center;
   margin: 0 1em;
   color: #2a3d92;
}
.divider::after,
.divider::before {
   content: "";
   border: 1px solid #2a3d92;
   -webkit-box-flex: 1;
       -ms-flex: 1;
           flex: 1;
}
.divider:not(:empty)::before {
   margin-right: 0.9em;
}
.divider:not(:empty)::after {
   margin-left: 0.9em;
}
.change-text {
   position: absolute;
   top: 0;
   right: 0;
   height: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   padding: 0 10px;
   color: #007bff;
   text-decoration: none;
}
.change-text:focus {
   -webkit-box-shadow: unset;
           box-shadow: unset;
   text-decoration: none;
}
.change-text:hover {
   text-decoration: underline;
}
.email-field {
   position: relative;
   margin-bottom: 0.75rem;
}
.email {
   margin-bottom: 0px;
}
.email[disabled] {
   background-color: #dfe2e4;
}
.email:-internal-autofill-selected {
   background-color: #068ee8 !important;
}
.submit-button {
   margin: 1em 0;
}
.name {
   margin-bottom: 0px;
}
.stripeForm {
    margin-bottom: 20px;
    padding-bottom: 5px;
    border: 0.5px solid #bfbfbf;
    border-radius: 4px;
}
@media screen and (min-width: 340px) {
  .Checkout {
    padding: 0 10px; 
  }
}
.Checkout {
  max-width: 800px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  
  display:table;
  margin:0 auto; 
}
.checkoutTitle span{
  display:block;
  font-size: 1.1rem;
  font-weight:600;
  padding:5px;
}
.stripeLabel {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  min-width: 318px;
  vertical-align: middle;
  margin-right: 30px;
  margin-bottom: 30px;
  margin: 20px auto;
  display: table;
}
.stripeButton {
  white-space: nowrap;
  border: 0;
  outline: 0;
  vertical-align: middle;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
          box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin: 20px auto;
  display: table;
}
.loading-spin{
  width: 32px;
  margin: 0 auto;
  display: table;
}
@media screen and (min-width: 490px) {
  .stripeForm {
   padding-left: 10px; 
  }
  .stripeButton {
    display: inline-block;
    margin-left: 15px;
  }
  .loading-spin{
    width: 32px;
    margin: 0 auto;
    display: inline-block;
  }
  .stripeLabel{
    display: inline-block;
  }
}
.stripeButton:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
          box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  -webkit-box-shadow: rgba(50, 50, 93, .14902) 0px 1px 3px, rgba(0, 0, 0, .0196078) 0px 1px 0px;
          box-shadow: rgba(50, 50, 93, .14902) 0px 1px 3px, rgba(0, 0, 0, .0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}
.StripeElement--focus {
  -webkit-box-shadow: rgba(50, 50, 93, .109804) 0px 4px 6px, rgba(0, 0, 0, .0784314) 0px 1px 3px;
          box-shadow: rgba(50, 50, 93, .109804) 0px 4px 6px, rgba(0, 0, 0, .0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
.StripeElement.PaymentRequestButton {
  padding: 0;
}
.page-heading{
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  font-style: italic;
  font-weight: unset;
  top:20px;
  position:relative;
  padding: 10px;
}
.page-footer{
  position:relative;
  bottom:-110px;
  display:table;
  margin: 0 auto;
  color:rgba(47, 47, 47, .541176);
  padding:0 5px 0 5px;
  margin-bottom: 30px;
}
.secured-payment strong{
  top: 30px;
  position: absolute;
}
.login{
  top: 30px;
  min-width: 320px
}
@media screen and (min-width: 490px) {
  .login {
    width: 490px;
    position:relative;
    left:auto;
  }
}
.lock-icon svg{
  display:inline-block;
  width: 25px;
  margin-right: 10px;
}
.input:disabled {
  background-color: #e2e2e2;
  cursor: not-allowed;
}
body {
  background-color: #F1F4F8;
}