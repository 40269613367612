
.header {
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}
.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; }
.contribute {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; }
.firstname{
    font-weight: bold;

}
.dashboard-header{
    display:table;
    margin:0 auto;
    margin-bottom:10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.input-search{
    margin: 10px 0px 10px 0px;
}
.dashlet-link{
    display:block;
    margin-bottom: 7px;
    padding: 7px;
    border-bottom: 1px solid black;
}
.resume-scan-report-item{
    margin-top: 7px;
}
.resume-scan-report-item a{
    margin-left: 5px;
    margin-right: 5px;
}
.dashlet-center{
    display:table;
    margin:0 auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.quickstart-dashlet{
    display:table;
    margin:0 auto;
}
.quickstart-dashlet-container {
    width: 100%;
}
.quickstart-dashlet-overdue{
    display:table;
    margin:0 auto;
    color: #0084bf;
}
.quickstart-dashlet-overdue:hover {
    cursor: pointer;
}
.sponsrSearchButton{
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.sponsrSearchButton:hover {
  text-decoration: none;
  color: #fff;
}
.sponsrSearchButton {
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  padding:20px;
  font-size: 18px;
  color: white;
  background-color: #ef5456;
}
.sponsrSearchButtonCalculateVisa {
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.sponsrSearchButtonCalculateVisa:hover {
  text-decoration: none;
  color: #fff;
}
.sponsrSearchButtonCalculateVisa {
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  padding:20px;
  font-size: 18px;
  color: white;
  background-color: #ef5456;
  width: 225px;
}
.quickStartButton {
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.quickStartButton:hover {
  text-decoration: none;
  color: #fff;
}
.quickStartButton {
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  padding:20px;
  font-size: 18px;
  color: #ef5456;
  background-color: white;
  border-color: #ef5456;
}
.quickStartButton:hover{
    color: #ef5456;
}
.quickstart-dashlet > a{
    display:block;
    margin:0 auto;
    margin-top: 10px;
}
.stepbystep-dashlet{
    display:table;
    margin:0 auto;
}
.stepbystep-dashlet a{
    display:table;
    margin:0 auto;
    margin-top: 20px;
}
@media (min-width: 768px) {
    .stepbystep-dashlet a{
        display:inline-block;
        margin-right: 7px;
        margin-top: 15px;
    }

}
.step1Button{
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.step1Button:hover {
  text-decoration: none;
  color: #fff;
}
.step1Button {
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  padding:10px;
  font-size: 16px;
  width: 205px;
  background-color: rgba(220, 75, 62, .48);
}
.step2Button{
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.step2Button:hover {
  text-decoration: none;
  color: #fff;
}
.step2Button {
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  padding:10px;
  font-size: 16px;
  width: 205px;
  background-color: rgba(220, 75, 62, .57);
}
.step3Button{
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.step3Button:hover {
  text-decoration: none;
  color: #fff;
}
.step3Button {
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  padding:10px;
  font-size: 16px;
  width: 205px;
  background-color: rgba(220, 75, 62, .71);
}
.step4Button{
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.step4Button:hover {
  text-decoration: none;
  color: #fff;
}
.step4Button {
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  padding:10px;
  font-size: 16px;
  width: 205px;
  background-color: rgba(220, 75, 62, .78);
}
.step5Button{
  display: inline;
  margin:0 auto;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background: #0F9D58;
}
.step5Button:hover {
  text-decoration: none;
  color: #fff;
}
.step5Button {
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  padding:10px;
  font-size: 16px;
  width: 205px;
  background-color: rgba(220, 75, 62, 1);
}
.dashboard {
    margin-top: 8%;
}
.futureVisa{
    font-weight: bold;
    font-size: 30px;
    display:table;
    margin:0 auto;
}
.delete-applied {
    margin-left: 10px;
}
h4 {
    text-align: center;
}
.searchButton {
    background-color: #2a3d92;
    border-color: #2a3d92;
    /* margin-left: -10px; */
}
.visaIcon {
    width: 22px;
    margin-left: 5px;
}
.locationIcon {
    width: 12px;
    margin-left: 8px;
    padding-bottom: 3px;
    background: #fafafa;
}
.getStarted {
    position: absolute;
    right: 1rem;
    top: 5rem;
}
.getStarted span {
    color: #0056b3;
    text-decoration: underline;
}
.getStarted span:hover {
    cursor: pointer;
}
.nextStepsTitle {
    margin-left: 5px;
    font-size: 18px;
}
.mainFields {
    margin-top: 3rem;
}
.infoContainer {
    background: #fff3cd;
    padding: 25px;
    font-size: 18px;
    margin-bottom: 2%;
    color: #8e6e13;
}
.searchJobTitlesInput {
    border-radius: 0%;
}
.infoContainer  a {
    text-decoration: underline;
    color: #037bff;
}
.createButtons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.colPaddings {
    padding-left: 2px; 
    padding-right: 2px;
    margin-top: 10px;
}
.colPaddings-visas {
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 1px;
}
.citiesField {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.citiesField span {
    padding: 8px;
    padding-left: 5px;
    padding-right: 0px;
    background: #fafafa;
    border: 1px solid #ccc;
    border-right: 0px;
}
.citisSearch {
    width: 100%;
}
.searchWarnings {
    font-size: 14px;
}
.searchWarningsLink {
    text-decoration: underline;
}
.titlesCard {
    margin-top: 2%;
}
.savedSearchContainer {
    width: 90%;
    margin: 0 auto;
}
.saveSearchTitlesCard {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.saveSearchTitlesCard span {
    font-weight: bold;
}
.saveSearchCard {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.saveSearchCardContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
}
.saveSearchCard-values {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}
.smTitlesCard {
    display: none;
}
.saveSearchOthers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    font-size: 13px;
    width: auto;
    margin-right: 10px;
    margin-left: 10px;
}
.saveSearchLink {
    font-size: 23px;
    margin-left: 25px;
    color: blue;
}
.saveSearchLink:hover {
    text-decoration: underline;
    cursor: pointer;
}
.saveSearchOptionsContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    margin-top: 0px;
}
.searchBarContainer {
    margin-left: 7%;
}
.hideBlock {
    display: none;
}
.cardLabel {
    width: 25%;
    font-weight: bold;
    text-decoration: underline;
}
.cardLabelVisa { 
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 10%;
    height: auto;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: bold;
    text-decoration: underline;
}
.cardLabelVisa > span:nth-child(2) {
    font-size: 14px;
}
.cardLabel:hover, .cardLabelVisa:hover {
    cursor: pointer;
}
.cardLabelSponsorship {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    font-size: 14px;
    margin-top: 10px;
}
.cardLabelSponsorship span {
    margin-right: 5%;
}
.cardLabelSponsorship-lassThreeDays {
    color: #28a745;
    text-decoration: underline;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.cardLabelSponsorship-guaranteed {
    color: #0056b3;
    text-decoration: underline;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.cardLabelSponsorship-goodChance {
    color: #FF4545;
    text-decoration: underline;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.cardLabelSponsorship-lassThreeDays:hover, .cardLabelSponsorship-guaranteed:hover, .cardLabelSponsorship-goodChance:hover {
    cursor: pointer;
}
.searchButtonContainer {
    display: inline;
    text-align: left!important;
}
.trashIcon {
    width: 22px;
    margin-left: 5px;
}
.trashIcon:hover {
    cursor: pointer;
}
.savedSearchCard > div:hover{
    -webkit-box-shadow: rgba(0, 0, 0, .3) 0px 0px 8px 1px;
            box-shadow: rgba(0, 0, 0, .3) 0px 0px 8px 1px;
    cursor:pointer;
}
.toggleContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.toggleContainer span {
    font-size: 15px;
    font-weight: 500;
    margin-left: 6px;
}
@media (max-width: 1024px) {
    .saveSearchCard-values {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .cardLabel {
        width: 100%;
    }
    .cardLabelVisa {
        width: 100%;
        margin-left: 0px;
    }
    .cardLabelSponsorship {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .cardLabelSponsorship span {
        margin-bottom: 1%;
    }
    .saveSearchOthers {
        width: 95%
    }
}
@media (max-width: 767px) {
    .savedSearchContainer {
        width: 100%;
    }
    .searchBarContainer {
        margin-left: 0;
    }
    .searchButtonContainer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    .saveSearchCard {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
    }
    .titlesCard {
        display: none;
    }
    .smTitlesCard {
        display: inline;
        font-weight: bold;
    }

    .saveSearchOptionsContainer {
        width: 100%;
        margin-top: 5%;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    }
    
    .trashIcon {
        margin-top: 15px;
    }

    .toggleJobAlert {
        margin-top: 10px;
    }
}
@media (max-width: 600px) {
    .colPaddings {
        padding-left: 15px; 
        padding-right: 15px;
        margin-top: 10px;
    }
    .colPaddings-visas {
        padding-left: 15px; 
        padding-right: 15px;
        margin-top: 1px;
    }
}
@media (max-width: 350px) {
    .createButtons {
        margin-top: 25px;
    }
}
@media (max-width: 767px) {
    .saveSearchOthers {
        margin: 3%;
    }
}
.visaVerifyContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.verifyLabel {
    margin-top: 10px;
    margin-left: 1%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.verifyLabelContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    margin-left: 1%;
}
.verifyLabel span, .verifyLabelContainer span {
    font-size: 17px;
}
.alertIcon {
    margin-bottom: 5px;
    margin-right: 5px;
}
.greenTickIcon {
    margin-right: 5px;
    width: 20px;
}
.verifyLabelLink {
    text-decoration: underline;
    color: #037bff;
    margin-left: 5px;
    margin-right: 15px;
    padding: 0px;
}
.verifyLabelLink:hover {
    cursor: pointer;
}
.alignModalButtons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.verifyEligibilityModalContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.verifyEligibilityModalContainer span {
    margin-bottom: 15px;
}
.verifyEligibilityModalButtons {
    margin-top: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.notPrequalifyContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.notPrequalifyContainer span {
    margin-bottom: 15px;
}
@media (max-width: 768px) {
    .visaVerifyContainer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .verifyLabel { 
        margin-left: 0;
    }
    .verifyEligibilityModalButtons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}
.checkboxModal {
    width: 5%;
    margin-right: 5px;
}
.verifyLabelButton {
    padding: 0;
    font-size: 17px;
    margin-right: 5px;
    margin-bottom: 3px;
}
.buttonVerify { 
    font-size: 18px;
    text-decoration: underline;
    color: #037bff;
}
.buttonVerify:hover {
    cursor: pointer;
}
.notificationButtonLink { 
    font-size: 18px;
    padding: 0;
    margin-bottom: 2px;
    text-decoration: underline;
}
.jobAlertLink {
    padding: 0;
    font-size: 18px;
    margin-bottom: 2px;
    text-decoration: underline;
}
.jobAlertLink:hover {
    cursor: pointer;
}
.alignPopupsButtons {
    float: right;
}
.alignPopupsButtons-Back {
    float: left;
}
.savedSearchPopup {
    width: 100%;
    margin-bottom: 3%;
}
.savedSearchPopupImg {
    width: 100%;
}
.noVisaMatchBox {
    border: 2px solid red;
}
.noVisaMatchLabel {
    font-size: 16px;
    font-weight: bold;
    color: red;
}
.visaOpenPopups {
    color: #0056b3;
    text-decoration: underline;
}
.imgModalContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.imgModalContainer img {
    width: 75%;
}
.modalLabel {
    background-color: #2a3d92;
    color:white;
    font-weight: bold;
    font-size: 11px;
    padding: 4px;
    border-radius: 4px;
    white-space: normal;
    display: inline-block;
}
.modalLabel:hover {
    cursor: pointer;
}
.savedSearchDeleteItem {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex
}
.categoryButton {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 6px 14px;
    margin-bottom: 8px;
    background-color: #f8f9fa;
    color: #333;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  }
.categoryButtonIcon {
    width: 16px;
    height: auto;
    margin-right: 6px;
    vertical-align: middle;
  }
@media (max-width: 767px) {
    .fixMobilePlaceholder {
      padding-left: 10px !important;
      padding-right: 4px !important;
    }
  }
  