span.selected-filters button{
  padding:0;
  margin:0px 3px 0px 3px;
}
button.clearAll{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  color:#ef5456;
  border:1px solid #ef5456;
  float:right;
}
button.clearAll:hover{
  background:#ef5456;
  color:white;
}
