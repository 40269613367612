
.dashlet-link{
    display:block;
    margin-bottom: 7px;
    padding: 7px;
    border-bottom: 1px solid black;
}
.touchcredits-dashlet{
    display:table;
    margin:0 auto;
}
.dashlet-center{
    display:table;
    margin:0 auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
