.ribbon-container {
  font-size: 20px;
  background-color: #fee066;
  color: #3a3a54;
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid black;
  z-index: 10000000;
}

.ribbon-container div {
  padding: 20px;
}

.ribbon-container button {
  top: 7px;
  right: 10px;
  position: absolute;
  border: 1px solid black;
  border-radius: 35px;
  height: 38px;
  background-color: transparent;
  cursor: pointer;
  margin:10px;
}
