.message {
  display: none;
  position: fixed;
  right:5px;
  padding: 24px;
  border-radius: 4px;
  color: #000;
  font-size: 16px;
  text-align: center;
  z-index: 10000;
  -webkit-box-shadow: 0 0 15px #888888;
  box-shadow: 0 0 15px #888888;
}
.message a {
  color: white;
  text-decoration: underline;
}
.show {
  display: block;
}
.success {
  background-color: #0F9D58;
  color: #fff;
}
.error {
  background-color: #ff0000;
  color: #fff;
}
