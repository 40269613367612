.page-header{
  background-color: #f7f4f2;
  margin-bottom: 10px;
  -webkit-box-shadow: 0 1px 6px 1px rgba(70, 70, 70, .3);
          box-shadow: 0 1px 6px 1px rgba(70, 70, 70, .3);
}

.logo {
  display: table;
  margin: 0 auto;
  height: 50px;
}

.logo {
  display: table;
  margin: 0 auto;
  height: 50px;
}