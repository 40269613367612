
.scoreboard {
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  width: 40%;
}
.header {
  margin: 0;
  padding: 24px;
  border-radius: 4px 4px 0 0;
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  background: #0F9D58;
  color: #fff;
}
.list {
  padding: 16px;
  list-style: none;
}
.item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.topic {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 200px;
          flex: 0 1 200px;
  font-size: 16px;
}
