
.header {
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; }

.contribute {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; }

.about{
  padding: 20px;
  background-color: white;
}