.industry-list{
  /* to keep even empty so that the class is added on the component defined in global.css*/
}


.must-select-visa{
  display:table;
  margin: 0 auto;
}


.must-select-visa img{
  margin-bottom: 40px;
  max-width: 280px;
}


.results-stats {
  min-height:90px;
  margin-top:15px;
  width: 100%;
}


.results-stats-users {
  min-height: 90px;
  margin-top: 15px;
  margin-right: 10px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}


.results-stats-users {
  min-height:90px;
}


.sort-container{
  width:100%;
  display:table;
}


.no-results{
  margin-top:80px;
  display:block;
  padding-left:55px;
}


.no-results-help{
  margin-top:10px;
  display:list-item;
}


.no-result-link {
  text-decoration: underline;
  color: #037bff;
}


.no-result-link:hover {
  cursor: pointer;
}


.total-hits {
  float:left;
  margin-right:3px;
  margin-left: 5px;
}


.subscribe-panel {
  margin-top:100px;
  background: rgba(40, 56, 145, .79);
  text-align: center;
  margin: 0 auto;
  position: fixed;
  left: 0;
  bottom: 0;
  font-size: 16px;
  padding: 20px;
  width: 100%;
  display:none;
}


.subscribe-panel a{
  color: #fff;
}


.visa-list ul{
  max-height: 100px;
}


.visa-message {
  color: red;
  font-weight: bold;
  font-size: 15px;
}


.saveSearchContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 15px;
}


.saveSearchContainer span {
  font-size: 14px;
  text-align: end;
}


.premiumModalContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}


.premiumModalButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}


.modalLabel {
  background-color: #2a3d92;
  color:white;
  font-weight: bold;
  font-size:12px;
  padding:3px;
  margin-left:10px;
  border-radius:4px;
  white-space: normal;
  display: inline-block;
}


@media screen and (min-width: 768px) {
  .must-select-visa img{
    max-width: 320px;
  }
  .grid{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;  
    -webkit-box-orient: horizontal;  
    -webkit-box-direction: normal;  
        -ms-flex-flow: row wrap;  
            flex-flow: row wrap;
  }
  .col-2-3 {
    width: 60%;
  }
  .col-1-3 {
    width: 40%;
  }
}


.completeProfileContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}


.completeProfileButton {
  float: right;
}


.sponsorshipHintContainer { 
  margin-top: 20px;
}


.sponsorshipHintContainer img:hover {
  cursor: pointer;
}


.verifyLink {
  font-size: 16px;
  line-height: 22px;
  color: #0056b3;
}


.verifyLink:hover{
  cursor: pointer; 
}


.firstSavedSearchImg {
  width: 100%;
}


.firstSavedSearchButton {
  float: right;
}


.confirmEligibilityHeader {
  text-align: left;
}


a.confirmEligibilityCloseButton {
  float: right;
  text-decoration: underline;
  color: #037bff;
  margin: .375rem .75rem 0 0;
}


a.confirmEligibilityCloseButton:hover {
  cursor: pointer;
}


.confirmEligibilityButton {
  float: right;
}


.invalidSavedSearchImg {
  width: 100%;
}


.invalidSavedSearchButton {
  float: right;
}


.buttonsModalContainer { 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}


.checkboxModal {
  width: 5%;
  margin-right: 5px;
}


.counterJobs {
  text-align: center;
  font-weight: bold;
}


.clickable-crisp {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}


.satisfactionCard {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 1rem 8px;
  padding: 1rem;
  background: #fff;
}


.buttonsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-top: 10px;
}


.satisfactionButton {
  cursor: pointer;
  text-align: center;
}


.satisfactionLabel {
  font-size: 13px;
  margin-top: 5px;
  font-weight: bold;
}


.satisfactionTitle {
  font-weight: bold;
}