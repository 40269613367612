
.container{
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  background: #fff;
  -webkit-box-pack:'justify-space-between';
      -ms-flex-pack:'justify-space-between';
          justify-content:'justify-space-between';
  font-size:16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin:10px;
  padding-bottom: 40px;
}
.jobcard-content{
  padding:15px;
  width:100%;
}
.contact-search{
  margin-top:30px;
  border-top:1px solid #7f8388;
}
.contact-search > h4{
  margin-top:30px;
  color: #2a3d92;
}
.contact-result{
  display:table;
  margin:0 auto;
  max-width: 700px;
  margin-top:30px;
}
.direct-approach-video{
  width:100%;
  height: 360px;
}
.hide{
  display:none;
}
