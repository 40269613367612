

@import url(https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500);
/* Add custom styles over third party components such as bootstrap */
p {
    text-align: justify;
    text-justify: inter-word;
}
.card{
    margin-bottom:20px;
}
.card-header{
    background-color: #2a3d92;
    color:white;
}
.col-xs-12{
    padding-right: 0px;
    padding-left: 0px;
}
.container{
    padding-right: 0px;
    padding-left: 0px;
}
@media screen and (max-width: 992px) {
    .container{
        padding-top:10px;
    }
}
.container>.navbar-header{
    margin-right:15px;
}
@media screen and (min-width: 992px) {
    .container>.navbar-header{
        margin-right:0px;
    }
    .col-xs-12{
        padding-right: 15px;
        padding-left: 15px;
    }
    .container{
        padding-right: 15px;
        padding-left: 15px;
    }
}
.navbar-collapse {
    padding-right: 30px;
    padding-left: 30px;
}
.navbar-light {
    background-color: #f7f4f2;
    border-color: #e7e7e7;
}
.navbar-collapse{
    background-color: #f7f4f2;
}
.dropdown-toggle::after{
    position: absolute;
    top: 30px;
}
.panel-primary>.panel-heading {
    background-color: #2a3d92;
    border-color: #337ab7;
}
.modal-backdrop.in {
    opacity: .8;
}
#basic-nav-dropdown span.caret{
    display:none;
}
@media (min-width: 992px){
    .navbar-light {
        height: 58px;
    }
    .navbar-nav>li>a {
        padding-bottom: 0px;
        padding-top: 8px;
    }
    .navbar-nav{
        height: 38px;
        position: absolute;
        right: 90px;
    }
}
/* Job search custom CSS */
#q-downshift-input {
    font-size:15px;
}
#industry-downshift-input div{
    font-size:16px;
}
text[class^='RadialGaugeLabel-module_valueLabel']{
    font-size: 13px;
}
div[class^='localIdentName-search-components__filters___'] h2{
    margin-top: 20px;
}
div[class^='localIdentName-search-components__filters___'] *{
    font-size:16px;
    line-height:22px;
}
/* Any dropdown values in filters container */
div[class^='localIdentName-search-components__filters___'] li > div{
    font-size:18px;
    line-height:20px !important;
}
/* Multi list filters BEGIN */
div[class^='localIdentName-jobsearch__industry-list'] li{
    height: 85px;
    border: solid 1px #e0e2e3;
    font-weight:0 !important;
    line-height: 20px;
    background-color: white;
}
div[class^='localIdentName-jobsearch__industry-list'] li label{
    font-weight:normal;
    margin-left:7px;
}
div[class^='localIdentName-jobsearch__industry-list'] li[class='active']{
    background-color: #cde3ff;
}
/* Multi list filters END */
div[class^='localIdentName-search-components__hide-filters___'] h2{
    margin-top: 20px;
}
div[class^='localIdentName-search-components__hide-filters___'] *:not(h3){
    font-size:16px;
    line-height:22px;
}
/* Any dropdown values in filters container */
div[class^='localIdentName-search-components__hide-filters___'] li > div{
    font-size:18px;
    line-height:20px !important;
}
div[class^='localIdentName-search-components__hide-results___'] > div {
    margin-top:25px;  
}
div[class^='localIdentName-search-components__results___'] > div {
    margin-top:25px;  
}
select[name='sort-options'] {
    display:none;
}
div[class^='localIdentName-search-components__searchbox-field__'] input {
    font-size:16px;
}
.cancel-icon{
    height: 18px;
}
input[id$='-downshift-input']{
    font-size:16px;
}
li[id^='q-downshift-item-'] > div{
    font-size:18px;
    line-height:20px !important;
}
div[class^='localIdentName-search-components__selected-filters__'] a{
    font-size:14px;
    line-height:20px;
}
.crisp-client .cc-1m2mf[data-maximized="false"] {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
}
@media (max-width: 991px) {
    .navigation {
      display: none !important;
    }
    .navigation.navigation-admin {
      display: block !important;
    }
    .navbar,
    .navbar-light {
      min-height: 60px !important;
      height: 60px !important;
      padding: 0 !important;
      margin: 0 !important;
      -webkit-box-align: center !important;
          -ms-flex-align: center !important;
              align-items: center !important;
    }
  }
  