

@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);* { -webkit-box-sizing: border-box; box-sizing: border-box; }body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}.content{
  width: 100%;
  margin-top:50px;
  margin-bottom: 50px;
  z-index: -1;
}.app {
  padding: 8px;
  height: 100%;
  font-weight: normal;
  font-smoothing: antialiased;
}@media screen and (min-width: 992px) {
  .content{
    width: 100%;
    margin-top:80px;
    z-index: -1;
  }
}/* Add your global styles below... *//* ex:

:global {
  & .red { color: red; }
  & .blue { color: blue; }
}

*/
