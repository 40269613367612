
.search-form {
  margin: 10px;
}
.search-form input {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0.75rem 1rem;
  border: 2px solid #c3c8ce;
  border-radius: 4px;
  width: 100%;

  background-color: #fff;
  color: #333f48;
  margin-bottom: 0.75rem;
  min-width: 0;
  margin: 5px;
  padding:6px;
}
.search-form button{
  margin:7px;
}
.search-form-filters{
  display:table;
  margin: 0 auto;
}
.search-buttons{
  display:table;
  margin: 0 auto;
}