
.form-container{
  width:90%;
  display:table;
  margin:0 auto;
}
.description{
  min-height:300px;
  max-width: 100%; 
  max-height: 100%;
  width: 100%;
}
.margin-block{
  margin-top: 25px
}
.potentialvisa-block {
  margin-top: 8px;
  margin-bottom: 8px;  
}
.required-field {
  color: red;
  font-size: 14px;
}
.phone-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
}
.save-button {
  margin: 20px;
}
.go-back-button{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  color:#2a3d92;
  border:1px solid #2a3d92;
}
.go-back-button::before{
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2026.676%2026.676%22%20style%3D%22enable-background%3Anew%200%200%2026.676%2026.676%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%09%3Cpath%20fill%3D%22%232a3d92%22%20d%3D%22M26.105%2C21.891c-0.229%2C0-0.439-0.131-0.529-0.346l0%2C0c-0.066-0.156-1.716-3.857-7.885-4.59%0A%09%09c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0%2C0.213-0.115%2C0.406-0.304%2C0.508c-0.188%2C0.098-0.413%2C0.084-0.588-0.033L0.254%2C13.815%0A%09%09C0.094%2C13.708%2C0%2C13.528%2C0%2C13.339c0-0.191%2C0.094-0.365%2C0.254-0.477l11.857-7.979c0.175-0.121%2C0.398-0.129%2C0.588-0.029%0A%09%09c0.19%2C0.102%2C0.303%2C0.295%2C0.303%2C0.502v4.293c2.578%2C0.336%2C13.674%2C2.33%2C13.674%2C11.674c0%2C0.271-0.191%2C0.508-0.459%2C0.562%0A%09%09C26.18%2C21.891%2C26.141%2C21.891%2C26.105%2C21.891z%22%2F%3E%0A%3C%2Fsvg%3E');
  background-position: left center;
  background-repeat: no-repeat;
  fill:white;
  background-size: contain;
  content: ' ';
  display: inline-block;
  height: 15px;
  position: relative;
  top: 2px;
  width: 20px;
}
.deleteButton {
  margin-top: 3%;
}
.deleteJobTitleButton {
  font-size: 14px;
  padding: 5px;
}
.zipCodeField {
  border: 1px solid red;
}
button.go-back-button:hover::before{
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2026.676%2026.676%22%20style%3D%22enable-background%3Anew%200%200%2026.676%2026.676%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%09%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M26.105%2C21.891c-0.229%2C0-0.439-0.131-0.529-0.346l0%2C0c-0.066-0.156-1.716-3.857-7.885-4.59%0A%09%09c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0%2C0.213-0.115%2C0.406-0.304%2C0.508c-0.188%2C0.098-0.413%2C0.084-0.588-0.033L0.254%2C13.815%0A%09%09C0.094%2C13.708%2C0%2C13.528%2C0%2C13.339c0-0.191%2C0.094-0.365%2C0.254-0.477l11.857-7.979c0.175-0.121%2C0.398-0.129%2C0.588-0.029%0A%09%09c0.19%2C0.102%2C0.303%2C0.295%2C0.303%2C0.502v4.293c2.578%2C0.336%2C13.674%2C2.33%2C13.674%2C11.674c0%2C0.271-0.191%2C0.508-0.459%2C0.562%0A%09%09C26.18%2C21.891%2C26.141%2C21.891%2C26.105%2C21.891z%22%2F%3E%0A%3C%2Fsvg%3E');
}
button.go-back-button:hover{
  color:white;
  background:#2a3d92;
}
@media (max-width: 770px) {
    .postal-field {
        margin-top: 15px;
    }
}