.containerIframe {
  position: relative;
}

.buttonContact-company {
  position: absolute;
  bottom: 10%;
  font-size: 25px;
  right: 8%;
}

.buttonContact-job {
  position: absolute;
  bottom: 15%;
  font-size: 25px;
  right: 8%;
}