.visa-match-score-row{
  display: inline;
}
.visa-match-score-noSponsorship {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.infoSpan {
  margin-left: 35px;
  font-size: 24px;
}
.infoSpan:hover {
  cursor: pointer;
}
.visa-select {
  margin-bottom: 7px;
}
.visa-match-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  max-height: 120px;
  min-width: 150px;
}
.visa-match-container-no-futurevisa {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  max-height: auto;
}
.visa-match-container-candidates {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.visa-match-container-visaValues {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.visa-match-container-visaType {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.visa-match-container-visaType > div {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.visa-match-container-sponsorship {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 55px;
}
.visa-match-container-titles {
  font-size: 16px;
  font-weight: bold;
}
.visa-match-container-images {
  margin-top: 18px;
  width: 35px;
  height: 35px;
}
@media screen and (min-width: 490px) {
  .visa-match-score-row{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}
.visa-chance-container fieldset{
  display:inline;
  vertical-align: text-top;
  width: 100%;
}
.chance-score-gauge{
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.chance-score-gauge svg{
  vertical-align: top;
}
.stats-main-container * {
  text-align: left;
}
.stats-container{
  margin-top: 10px;
  margin-bottom: 14px;
  padding:5px;
  font-size: 14px;
}
.chance-row *{
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.visa-count-row{
  position: relative;
  top: 5px;
}
.visa-count-row div{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-right: 15px;
}
.visa-match{
  border: 1px solid #2a3d92;
  border-radius: 5px;
  margin-bottom: 20px;
}
.visa-match > div{
  padding: 10px 10px 15px 15px;
}
.visa-match legend{
  margin-left: 10px;
  padding:3px;
  font-size:14px;
  width: 102px;
  color:#2a3d92;
  margin-bottom: 0px;
}
.approved svg{ 
  display:inline;
  height:18px;
}
.withdrawn svg{ 
  display:inline;
  width:18px;
  fill:darkorange;
  stroke:darkorange;
}
.denied svg{ 
  display:inline;
  width:18px;
  fill:red;
  stroke:red;
}
.petition-count{
  position: relative;
  bottom: 2px;
  margin-left:3px;
}
.visa-guaranteed-container {
  width: auto;
  font-size: 18px;
  font-weight: bold;
  color: green;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.visa-guaranteed-container > img {
  width: 25px;
  height: 25px;
  margin-left: 15px;
  margin-right: 10px;
}
.visa-guaranteed-container > span {
  text-align: center;
}
.chanceText-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 15px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
.alert-container {
  width: 300px;
  text-align: center;
}
.alert-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
 font-size: 18px;
  font-weight: bold;
  color: green;
  text-align: center;
}
.alert-close {
  font-weight: bold;
  font-size: 20px;
  margin-left: 20px;
}
.alert-close:hover {
  cursor: pointer;
}
.visa-j1program {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: green;
  text-align: center;
  margin-top: 20px;
}
.sponsorshipHintModalButton {
  float: right;
}
.lockIcon:hover {
  cursor: pointer;
}
.visa-match-verified {
  width: 26px;
  height: 18px;
  margin-bottom: 3px;
}
.visa-match-verified-info {
  width: 28px;
  height: 21px;
  margin-bottom: 3px;
}
.visa-match-container-hint {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.visa-match-container-link {
  text-decoration: underline;
  color: white;
}
.getBundle {
  margin-top: 10px;
  font-size: 17px;
  font-weight: 500;
}
.tooltipContent {
  text-align: left;
}
.availableImg {
  width: 50px;
}