
.pure-table td,.pure-table th{
  border-left:1px solid #cbcbcb;
  border-width:0 0 0 1px;
  font-size:inherit;
  margin:0;
  overflow:visible;
  padding:.5em 1em
}

.pure-table td:first-child,.pure-table th:first-child{
  border-left-width:0
}

.pure-table thead{
  background-color:#e0e0e0;
  color:#000;
  text-align:left;
  vertical-align:bottom
}

.pure-table td{
  background-color:transparent
}

.pure-table-odd td,.pure-table-striped tr:nth-child(2n-1) td{
  background-color:#f2f2f2
}

.pure-table-striped tr:hover{
  background-color:rgba(42, 61, 146, .35);
}

.pure-table-striped tr{
  height:40px;
}

.pure-table-striped td{
  width:20px;
  padding: 5px;
}

th{
  padding: 5px;
}

input {
  width: 100%;
}

.admin{
  margin-top: 70px;
  display: table;
  margin: 0 auto;
}
