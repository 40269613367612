.stepsTitleText {
  font-weight: bold;
  font-size: 18px;
}

.warningsContainer {
  margin-top: 3%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-size: 18px;
}

.alertIcon {
  margin-top: 4px;
  margin-right: 5px;
}

.tickIcon {
  width: 20px;
}

.buttonsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.buttonsContainer span {
  font-size: 17px;
}

.buttonApplying {
  width: 210px;
  margin-top: 2%;
}

.uploadResumeContainerRow {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.uploadResumeContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 200px;
  background: white;
  padding: 1%;
}