
.switch-container{
	margin: 25px 0 15px 0;
}
.switch-container h2{
	display:inline;
  font-weight: 500;
  color: #424242;
}
.switch-container h3{
	display:inline;
	font-size: 14px;
  font-weight: 500;
  color: #424242;
}
input[type="checkbox"].switch  {
  position: absolute;
  right: 25px;
  opacity: 0;
  width: 150px;
	height:48px;
	cursor: pointer;
}
input[type="checkbox"].switch + div {
	vertical-align: middle;
	width: 40px;
	height: 20px;
	border-radius: 999px;
	background-color: rgb(168, 168, 168);
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-property: background-color, box-shadow;
	-webkit-transition-property: background-color, -webkit-box-shadow;
	transition-property: background-color, -webkit-box-shadow;
	transition-property: background-color, box-shadow;
	transition-property: background-color, box-shadow, -webkit-box-shadow;
	cursor: pointer;
}
input[type="checkbox"].switch + div span {
	position: absolute;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
}
input[type="checkbox"].switch + div span:nth-child( 1 ) {
    margin-left: 15px;
}
input[type="checkbox"].switch + div span:nth-child( 2 ) {
    margin-left: 55px;
}
input[type="checkbox"].switch:checked + div {
	width: 54px;
	background-position: 0 0;
	background-color: #a3a3a3;
}
input[type="checkbox"].switch + div {
	width: 54px;
  height: 28px;
  float: right;
}
input[type="checkbox"].switch:checked + div {
	background-color: #57BB00;
}
input[type="checkbox"].switch + div > div {
	float: left;
	width: 26px;
	height: 26px;
	border-radius: inherit;
	background: #ffffff;
	-webkit-transition-timing-function: cubic-bezier(1,0,0,1);
	transition-timing-function: cubic-bezier(1,0,0,1);
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-property: transform, background-color;
	-webkit-transition-property: background-color, -webkit-transform;
	transition-property: background-color, -webkit-transform;
	transition-property: transform, background-color;
	transition-property: transform, background-color, -webkit-transform;
	pointer-events: none;
	margin-top: 1px;
	margin-left: 1px;
}
input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(20px, 0, 0);
	transform: translate3d(20px, 0, 0);
	background-color: #ffffff;
}
input[type="checkbox"].bigswitch.switch + div > div {
	width: 44px;
	height: 34px;
	margin-top: 1px;
}
input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(26px, 0, 0);
	transform: translate3d(26px, 0, 0);
}