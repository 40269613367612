.searchbox{
  position:fixed;
  top:60px;
  left:0;
  width:100%;
  z-index: 10;
  background:#ffffff;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .28);
          box-shadow: 0 4px 4px rgba(0, 0, 0, .28);
  min-width: 300px;
}
.searchbox button{
  width:55px;
  border: 1px solid #ccc;
  border-right:none;
  height:42px;
}
.searchbox svg{
  stroke:#2a3d92;
  fill:#2a3d92;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}
.searchbox-toggle {
  background:#f7f4f2;
  cursor: pointer;
}
.searchbox-toggle-selected {
  background:#2a3d92;
}
.searchbox-toggle-selected svg{
  fill:white;
}
.searchbox-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.searchbox-container{
  
  display:-webkit-box;
  
  display:-ms-flexbox;
  
  display:flex;
  margin:10px 10px 10px 10px;
}
.searchbox-field{
  width:100%;
}
.results{
  margin-top:150px;
}
.hide-results{
  display:none;
}
.hide-filters{
  display:none;
}
.filter-result-view-switch{
  position:fixed;
  bottom:65px;
  z-index: 0 !important;
  left: 85%;
  -webkit-transform: translateX(-85%);
          transform: translateX(-85%);
}
.filter-result-view-switch button{
  padding:8px;
  width:63px;
  font-size: 32px;
  color:white;
  border-radius:2em;
  background:#2a3d92;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
          box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
  outline:0;
  font-weight: bold;
  cursor: pointer;
}
.filter-result-view-switch svg{
  fill:white;
  height:24px;
}
.footer-results{
  margin-top:100px;
}
.filters{
  max-width:600px;
  margin-top:165px;
  padding-bottom: 130px;
  height:100%;
}
.filters > div{
  margin-top:65px;
  min-width:227px;
  width:100%;
  background-color: white;
  border: solid 1px #e0e2e3;
  border-radius: 7px;
  padding: 20px 20px 20px 20px;
}
/* FF tricks to let users scroll to bottom*/
@-moz-document url-prefix() {
  .filters > div{
    margin-bottom:300px;
  }
}
.selected-filters a{
  line-height:20px;
}
.selected-filters a{
  font-size:14px;
}
.error-message {
  color: #ed193a;
  font-size: 18px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 4px;
  padding:10px;
  border: #ed193a 2px solid;
  border-radius: .8em;
  margin-top: 80px;
}
.error-message button{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  display:table;
  margin: 0 auto;
  margin-top: 20px;
  color: #337ab7;
}
.subscribe-panel {
  margin-top:100px;
  background: rgba(40, 56, 145, .79);
  text-align: center;
  margin: 0 auto;
  position: fixed;
  left: 0;
  bottom: 0;
  font-size: 16px;
  padding: 20px;
  width: 100%;
  display:none;
}
.subscribe-panel a{
  color: #fff;
}
@media screen and (min-width: 768px) {
  .results{
    margin-top:80px;
    padding-left: 10px;
  }
  .filters{
    position: fixed;
    z-index: 2;
    width:inherit;
    min-width:150px;
    overflow-y: auto;
    height:100%;
    left:0;
    padding: 10px 10px 240px 10px;
    margin-top:unset;
  }
  .filters > div{
    
  }
  .results select{
    left: 108px !important;
  }
  .hide-filters{
    display:unset;
  }
  .hide-results{
    display:unset;
  }
  .filter-result-view-switch{
    display:none;
  }
}
@media screen and (min-width: 992px) {
  .searchbox{
    top:58px;
  }
}