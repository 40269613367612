.resultcard{
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  background: #fff;
  -webkit-box-pack:'justify-space-between';
      -ms-flex-pack:'justify-space-between';
          justify-content:'justify-space-between';
  font-size:16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin:6px;
}
.resultcard-selected{
  background: #cee3ff;
}
.resultcard-content{
  padding:15px;
  width:100%;
}
.resultcard > div:hover{
  -webkit-box-shadow: rgba(0, 0, 0, .3) 0px 0px 8px 1px;
          box-shadow: rgba(0, 0, 0, .3) 0px 0px 8px 1px;
  cursor:pointer;
}
.selectedcard {
  -webkit-box-shadow: rgba(0, 0, 0, .3) 0px 0px 8px 1px;
          box-shadow: rgba(0, 0, 0, .3) 0px 0px 8px 1px;
  cursor:pointer;
}
.description h4{
  font-size: 16px;
}
.description h3{
  font-size: 16px;
}
.description h2{
  font-size: 16px;
}
.description h1{
  font-size: 16px;
}
.title{
  font-weight: bold;
  text-decoration:none;
  color: #ef5456;
  margin-bottom:7px;
}
.new-job{
  margin-left:5px;
  font-weight:bold;
  color: #0F9D58;
}
.exclusive{
  font-weight:bold;
  color: #ed193a;
  margin:5px;
}
.exclusive{
  font-weight:bold;
  color: #ed193a;
}
.company{
  margin-bottom:3px;
}
.posted{
  margin-top:20px;
  color: #7f8388;
  float:right;
}
.industry{
  color: #7f8388;
  float:left;
}
.userDetails-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.userDetails-basicInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 18px;
  margin-top: 3%;
}
.userDetails-basicInfo-name {
  font-weight: bold;
}
.updateAt {
  -ms-flex-item-align: end;
      align-self: flex-end;
  color: #7f8388;
  float: right;
}
.userCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.userCard-firstSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
}
.userCard-firstSectionAllElements {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.jobsSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media (max-width: 960px) {
  .userCard {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
         -ms-flex-direction: column;
             flex-direction: column;
  }
   
  .userCard-firstSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.location::before{
  background-image: url('data:image/svg+xml;charset=utf-8,<svg%20xmlns%3D"http%3A//www.w3.org/2000/svg"%20width%3D"1178"%20height%3D"1767"><path%20fill%3D"%23b0b4b6"%20stroke%3D"%23b0b4b6"%20d%3D"M884%20589c0-81-29-151-87-208-57-58-127-86-208-86s-151%2028-208%2086-86%20127-86%20208%2028%20151%2086%20208c57%2058%20127%2087%20208%2087s151-29%20208-87c58-57%2087-127%2087-208zm294%200c0%2084-13%20152-38%20206l-419%20891a148%20148%200%200%201-264%200L39%20795C13%20741%200%20673%200%20589c0-162%2058-301%20173-416S427%200%20589%200s302%2058%20417%20173%20172%20254%20172%20416z"/></svg>');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  content: ' ';
  display: inline-block;
  height: 15px;
  position: relative;
  top: 2px;
  width: 20px;
}
.more-info{
  font-size:16px;
}
.actions-button{
  display:table;
  width:100%;
}
.actions-button div{
  display:table;
  margin:0 auto;
}
button.apply{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  color:#ef5456;
  border:1px solid #ef5456;
}
button.apply:hover{
  background:#ef5456;
  color:white;
}
button.edit{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  color:#2a3d92;
  border:1px solid #2a3d92;
}
button.edit:hover{
  background:#2a3d92;
  color:white;
}
.location{
  color: #7f8388;
  margin-bottom:7px;
}
.description{
  display:inherit;
  margin-bottom:25px;
  margin-top:25px;
}
.visa-guaranteed-info {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.visa-guaranteed-container {
  width: auto;
  font-size: 18px;
  font-weight: bold;
  color: green;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; 
  -ms-flex-wrap: wrap; 
      flex-wrap: wrap;
}
.visa-guaranteed-container > img {
  width: 25px;
  height: 25px;
  margin-left: 15px;
  margin-right: 10px;
}
.visa-guaranteed-container > span {
  text-align: center;
}
.infoSpan {
  margin-left: 35px;
  font-size: 24px;
}
.infoSpan:hover {
  cursor: pointer;
}
.visa-j1program {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: green;
  text-align: center;
  margin-top: 20px;
}
.greeFlag-AppliedJob {
  float: right;
  color: green;
  font-weight: bold;
  font-size: 20px;
}
.moreDetails-candidate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.listedJobsOptions {
  margin-bottom: 3%;
  width: 250px;
}
.listedJobsOptionsImg {
  width: 15%;
}
.listedJobsOptionsImg:hover {
  cursor: pointer;
}
@media screen and (min-width: 490px) {
  .resultcard{
    margin:10px;
  }
  .description {
    display:inherit;
    margin-bottom:25px;
    margin-top:25px;
  }

  .visa-match-guaranteed{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .visa-match-guaranteed > fieldset {
    margin-right: 10px;
  }
  .visa-match-guaranteed > div {
    text-align: center;
    margin-top: 2px;
  }
  .visa-guaranteed-container > div{
    padding-left: 7px;
    padding-right: 7px;
  }

  .visa-guaranteed-container-noSponsorship > div{
    padding-left: 7px;
    padding-right: 7px;
    width: 150px;
  }
}
.hiddenCompanyBannerLg, .hiddenCompanyBannerSm {
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.43922);
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.43922);
}
.hiddenCompanyBannerLg {
  height: 220px;
  width: 100%;
  max-width: 680px;
}
.hiddenCompanyBannerSm {
  width: 100%;
}
.jobExtraInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.missingDataContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 70%;
}
.messageRecruiterContainer {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.missingDataWarning {
  color: red;
  width: 50%;
  text-align: center;
}
.missingDataButton-edit {
  background-color: #fff;
  border-color: #2a3d92;
  color: #2a3d92;
}
.missingDataButton-reapply {
  background-color: #2a3d92;
  border-color: #2a3d92;
}
@media (max-width: 992px) { 
  .jobExtraInfo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
  }
  .missingDataContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 5%;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    width: 100%;
  }
}
@media (max-width: 780px) { 
  .missingDataContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .missingDataWarning {
    width: 100%;
  }
  .missingDataButtonCont {
    margin-top: 5%;
  }
}
.closedJob {
  float: right;
  color: red;
  font-weight: bold;
  font-size: 20px;
}
.expiredJob {
  color: red;
  font-weight: bold;
  font-size: 20px;
}
.experienceContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.textBold {
  font-weight: bold;
}
.skillsContainer {
  margin: 3%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
.skillsContainer div {
  padding: 10px;
  border: 1px solid green;
  border-radius: 1px;
  font-size: 17px;
  font-weight: 600;
  color: green;
  margin: 5px;
}
.skillsContainer div mark {
  color: green;
}
.visaBoxSide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.resumeImagesStatus {
  width: 22px;
  margin: 2px;
  margin-bottom: 4px;
}
.resumeImagesStatus-cross {
  width: 17px;
  margin: 2px;
  margin-bottom: 4px;
}
.alertIcon {
  margin-top: 3px;
  margin-right: 5px;
}
.skillsMatchesTitle {
  font-size: 1rem;
  font-weight: 500;
  color: dimgrey;
  margin: 0 0 7px 0;
}
.skillsMatchesContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.skillsMatchesContainer span {
  padding: 10px;
  border: 1px solid red;
  border-radius: 1px;
  font-size: 17px;
  font-weight: 600;
  color: red;
  margin: 5px;
}
.more-info-button{
  margin:10px;
  padding:10px;
  font-size: 16px;
  color:#2a3d92;
  border-radius:.3em;
  outline:0;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  background:white;
  cursor: pointer;
  color:#2a3d92;
  border:1px solid #2a3d92;
  margin:unset;
  margin-bottom: 10px;
  width:100%;
}
button.more-info-button:hover{
  color:white;
  background:#2a3d92;
  margin:unset;
  margin-bottom: 10px;
}
.subscriptionStatus {
  margin-top: 20px;
}
.subscriptionStatusActive {
  color: #28a745;
  font-weight: 600;
}
.subscriptionStatusNotActive {
  color: #ed193a;
  font-weight: 600;
}
.share {
  cursor: pointer;
  border:none;
  font-size: 20px;
  background:none;
  float:right;
}
.share svg{
  stroke-width: 2px;
  width:30px;
}
.top-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  /* margin-top: -15px; to align the title with the top of the card */
}
.title {
  margin: 0;
  padding: 0;
}
.actions-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 5px;
  margin: 0;
  padding: 0;
}
.apply {
  margin: 0;
  padding: 5px 10px;
}
.bottom-actions-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* gap: 1rem; */
  width: 100%;
}
.result-card-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 1rem;
}
.bottom-actions-container .share {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}
.result-card-footer .bottom-actions-container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  gap: 0.5rem;
  padding: 1rem 0;
}
.jobcard-button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.jobcard-button-bookmark {
  border: 1px solid #364151;
  border-radius: 4px;
  padding: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 40px;
  width: 40px;
  background-color: transparent;
}
.jobcard-button-apply {
  background-color: #283891;
  border: 1px solid #364151;
  /* border: none; */
  border-radius: 4px;
  padding: 6px 10px;
  color: #fff;
  cursor: pointer;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jobcard-button-share {
  background-color: transparent;
  border: 1px solid #364151;
  /* border: none; */
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jobcard-button-report {
  background-color: #EAE8E5;
  border: 1px solid #364151;
  border-radius: 4px;
  padding: 6px 10px;
  color: #070614;
  cursor: pointer;
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
