.button-container {
  display: block;
}

.button-container > button,
.button-container > a{
  margin-top: 20px;
  width: 100%;
}

@media (min-width: 992px) {
  .button-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 400px;
  }
  .button-container > button,
  .button-container > a {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}