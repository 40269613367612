.main-section {
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  width: 50%;
  border-radius: 4px;
}
.header {
  margin: 0;
  padding: 24px;
  border-radius: 4px 4px 0 0;
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  background: #ed193a;
  color: #fff;
}
.list {
  list-style: none;
  padding: 16px;
}

