.footer {
    margin-top: 80px;
    color: #999;
    background-color:black;
    bottom:0;
    left:0;
    width:100%;

}

.footer a{
  color: #999;
}

.footer-container {
    border-top: 1px solid #ccc;
    padding: 20px 15px;
}

.links {
    margin: 0;
    padding: 0; 
}

.links li {
    display: inline-block;
    padding-right: 10px;
    margin-right: 10px;
    list-style: none;
    border-right: 1px dotted #999;
}

.links li:last-child {
    padding-right: 0;
    margin-right: 0;
    border: none;
}