.fetch-error{
    color: red;
    font-weight:bold;
}

.fetch-error div{
    display:table;
    margin: 0 auto;
}

.contact-item-body{
    padding:10px;
}

.avatar svg{ 
    display:inline;
    height:48px;
    fill:gray;
}

.view-details{
    display:table;
    margin: 0 auto;
}

.input-email{
    width:100%;
    max-width:170px;
}

.location{
    color: #7f8388;
    margin-bottom:5px;
  }

.location::before{
    background-image: url('data:image/svg+xml;charset=utf-8,<svg%20xmlns%3D"http%3A//www.w3.org/2000/svg"%20width%3D"1178"%20height%3D"1767"><path%20fill%3D"%23b0b4b6"%20stroke%3D"%23b0b4b6"%20d%3D"M884%20589c0-81-29-151-87-208-57-58-127-86-208-86s-151%2028-208%2086-86%20127-86%20208%2028%20151%2086%20208c57%2058%20127%2087%20208%2087s151-29%20208-87c58-57%2087-127%2087-208zm294%200c0%2084-13%20152-38%20206l-419%20891a148%20148%200%200%201-264%200L39%20795C13%20741%200%20673%200%20589c0-162%2058-301%20173-416S427%200%20589%200s302%2058%20417%20173%20172%20254%20172%20416z"/></svg>');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    content: ' ';
    display: inline-block;
    height: 15px;
    position: relative;
    top: 2px;
    width: 20px;
}

.contact-actions-icon{
    width:48px;
    height:48px;
    margin-left:10px;
}

@media (min-width: 700px) {
    .contact-actions-icon{
        width:32px;
        height:32px;
        margin-left:10px;
    }
}