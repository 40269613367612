.label-container{
  display:block;
  width:100%;
  word-wrap:break-word;
}

.label{
  background-color: #7f8388;
  color:white;
  font-weight: bold;
  font-size:12px;
  padding:3px;
  margin-top:10px;
  margin-right:10px;
  border-radius:4px;
  white-space: normal;
  display: inline-block;
}