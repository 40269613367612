.stripeForm {
    margin-bottom: 20px;
    padding-bottom: 5px;
    border: 0.5px solid #bfbfbf;
    border-radius: 4px;
}

@media screen and (min-width: 340px) {
  .Checkout {
    padding: 0 10px; 
  }
}

.Checkout {
  max-width: 800px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  
  display:table;
  margin:0 auto; 
}

.checkoutTitle span{
  display:block;
  font-size: 1.1rem;
  font-weight:600;
  padding:5px;
}

.stripeLabel {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  min-width: 318px;
  vertical-align: middle;
  margin-right: 30px;
  margin-bottom: 30px;
  margin: 20px auto;
  display: table;
}

.stripeButton {
  white-space: nowrap;
  border: 0;
  outline: 0;
  vertical-align: middle;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
          box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin: 20px auto;
  display: table;
}

.loading-spin{
  width: 32px;
  margin: 0 auto;
  display: table;
}

@media screen and (min-width: 490px) {
  .stripeForm {
   padding-left: 10px; 
  }
  .stripeButton {
    display: inline-block;
    margin-left: 15px;
  }
  .loading-spin{
    width: 32px;
    margin: 0 auto;
    display: inline-block;
  }
  .stripeLabel{
    display: inline-block;
  }
}

.stripeButton:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
          box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  -webkit-box-shadow: rgba(50, 50, 93, .14902) 0px 1px 3px, rgba(0, 0, 0, .0196078) 0px 1px 0px;
          box-shadow: rgba(50, 50, 93, .14902) 0px 1px 3px, rgba(0, 0, 0, .0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus {
  -webkit-box-shadow: rgba(50, 50, 93, .109804) 0px 4px 6px, rgba(0, 0, 0, .0784314) 0px 1px 3px;
          box-shadow: rgba(50, 50, 93, .109804) 0px 4px 6px, rgba(0, 0, 0, .0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.PaymentRequestButton {
  padding: 0;
}