.subscription {
  display: block;
  border: 1px solid rgba(128, 128, 128, 0.38039);
  background-color: rgba(128, 128, 128, 0.12941);
}

.subscription > div{
  padding: 10px;
  margin: auto;
}

@media screen and (min-width: 490px) {
  .subscription {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
