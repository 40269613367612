
.topic-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.topic {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 200px;
          flex: 0 1 200px;
  font-size: 16px;
}
.button {
  font-size: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  margin: 0 28px;
  color: #fff;
}
.increment {
  background-color: #0F9D58;
}
.decrement {
  background-color: #2196F3;
}
.destroy {
  background-color: #db4437;
}

